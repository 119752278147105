<template>
  <div class="w-1/3 px-4">
    <div 
      class="w-full border border-grey-darker-100 rounded text-center py-20 cursor-pointer"
      :class="{
        'purple-light-shadow': item.id == activeItem
      }"
      @click="setItem(item)"
    >
      <span 
        class="base-font-gray-200 text-xl"
        :class="{
          'purple-text': item.id == activeItem
        }"
      >
        {{ item.title }} 
      </span>
    </div>
    <ul class="pl-5 mt-10">
      <li
        v-for="text in item.text"
        :key="text"
        class="text-base text-black list-disc"
      >
        {{ text }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: [ 'item', 'activeItem' ],
    methods: {
      setItem(item){
        this.$emit('setItem', item)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>